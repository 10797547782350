/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/npm-modernizr@2.8.3/modernizr.min.js
 * - /npm/jquery@1.12.4/dist/jquery.min.js
 * - /npm/jquery-smooth-scroll@2.2.0/jquery.smooth-scroll.min.js
 * - /npm/prefixfree@1.0.0/prefixfree.min.js
 * - /npm/prefixfree@1.0.0/plugins/prefixfree.viewport-units.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
